const routes = [
  {
    path: 'followupManage',
    name: 'followupManage',
    component: () => import(/* webpackChunkName: "followupManage" */ '@/views/Module_FollowupManage'),
    meta: {
      title: '随访管理'
    }
  },
  {
    path: 'followupDetail',
    name: 'followupDetail',
    component: () => import(/* webpackChunkName: "followupDetail" */ '@/views/Module_FollowupManage/Page_FollowupDetail'),
    meta: {
      title: '随访管理 / 随访详情'
    }
  },
  {
    path: 'patientFiles',
    name: 'patientFiles',
    component: () => import(/* webpackChunkName: "patientFiles" */ '@/views/Module_FollowupManage/Page_PatientFiles'),
    meta: {
      title: '随访管理 / 患者档案'
    }
  }
]

export default routes
