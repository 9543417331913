const routes = [
  {
    path: 'patientManage',
    name: 'Patient',
    component: () => import(/* webpackChunkName: "Patient" */ '@/views/Module_PatientManage/'),
    meta: {
      title: '患者管理'
    },
    children: [
      {
        path: 'patientList',
        name: 'PatientList',
        component: () => import(/* webpackChunkName: "PatientList" */ '@/views/Module_PatientManage/Page_PatientList/'),
        meta: {
          title: '患者列表'
        }
      }
    ]
  }
]

export default routes
