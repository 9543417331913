const routes = [
  {
    path: 'pendingTask',
    name: 'pendingTask',
    component: () => import(/* webpackChunkName: "pendingTask" */ '@/views/Module_PendingTaskManage'),
    meta: {
      title: '待处理任务'
    }
  }
]

export default routes
