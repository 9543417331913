const routes = [
  {
    path: 'addDoctor',
    name: 'AddDoctor',
    component: () => import(/* webpackChunkName: "AddDoctor" */ '@/views/Module_AddDoctorManage'),
    meta: {
      title: '添加医生'
    }
  }
]

export default routes
