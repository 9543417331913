import Vue from 'vue'
import VueRouter from 'vue-router'
// import Layout from '../layout'
import pendingtask from './module/pendingtask_module'
import patient from './module/patient_module'
import followup from './module/followup_module'
import enterpatient from './module/enterpatient_module'
import adddoctor from './module/adddoctor_module'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: () => import(/* webpackChunkName: "Layout" */ '@/layout'),
    redirect: '/pendingTask',
    children: [
      {
        path: 'information',
        name: 'Information',
        component: () => import(/* webpackChunkName: "Information" */ '@/views/Information/'),
        meta: {
          title: '个人信息'
        }
      },
      ...pendingtask,
      ...patient,
      ...followup,
      ...enterpatient,
      ...adddoctor
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login/index')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.replace = function replace (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
router.beforeEach((to, from, next) => {
  const token = window.sessionStorage.getItem('Authorization')
  if (to.path !== '/login' && !token) {
    next({
      path: '/login'
    })
  } else { next() }
})
export default router
