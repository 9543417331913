const routes = [
  {
    path: 'enterPatient',
    name: 'EnterPatient',
    component: () => import(/* webpackChunkName: "EnterPatient" */ '@/views/Module_EnterPatientManage/'),
    meta: {
      title: '录入病人'
    }
  }
]

export default routes
