import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/style/index.scss'
import to from 'await-to-js'
import '@/style/iconfont.css'

Vue.prototype.$to = to
Vue.config.productionTip = false
Vue.use(Element)
Vue.prototype.$store = store

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
